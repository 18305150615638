import React from "react";
import {Filter} from "./Filter";
import '../accessioning.css';

/**
 * Does given column have any features?  (i.e. can be sorted and/or filtered?)
 * @param column
 * @returns {boolean}
 */
const columnHasFeatures = (column) => (column.getCanSort() || column.getCanFilter());

/**
 * Given an array of column names, return a copy of that array with given column removed.
 * @param cols - array of column names
 * @param value - column name to remove
 * @returns {*[]} - If value not in array, returns copy of original array.
 *                    Otherwise, returns copy of array with given column value removed.
 */
const removeColumnById = (cols, value) => {
  const newCols = [...cols];
  const index = cols.indexOf(value);
  if (index > -1) newCols.splice(index, 1);
  return newCols;
}

/**
 * Handle array of which columns are showing their features components (sorting and/or filtering) in header
 * @typedef {Object} showFeaturesObj
 * @property {string[]} showColumnFeatures - array of column names whose headers are currently showing their feature components
 * @property {function} setShowColumnFeatures - function to set showColumnFeatures array
 */

/**
 * React Component to render header for a table column
 * @param {string} title - Text to render for column header
 * @param {Object} column - tanstack column def
 * @param {showFeaturesObj} showFeatures - true/false - show or hide controls for sorting/filtering features
 * @param {string} hint - text to show when user hovers over title.
 * @returns {JSX.Element}
 * @constructor
 */
const TableHeader = ({title, column, showFeatures, hint=''}) => {
  // If column has no features, just render header text ...
  if (!columnHasFeatures(column))
    return <span style={{textTransform: "uppercase", fontSize: "80%", fontWeight: "normal"}}>{title}</span>;

  // If column has features but is not currently showing them ...
  if (showFeatures.showColumnFeatures.indexOf(column.id) === -1) {
    return (
      <button
        className="ts-table-header-button"
        onClick={() => {
          if (column.getCanSort() && !column.getIsSorted()) column.toggleSorting(false);
          showFeatures.setShowColumnFeatures([...showFeatures.showColumnFeatures, column.id]);
        }}
        title={hint ? `Show ${hint}` : ''}
      >
        {title}
      </button>
    )
  }

  // Column has features, and is showing them ...
  //     show sorting arrows, if applicable
  //     display column title (button to hide features)
  //     display filter input-box, if applicable
  return (
    <>
      {column.getCanSort() && column.getIsSorted() ?
          <button
            className="ts-table-header-button" style={{padding: 0}}
            onClick={() => {
              column.toggleSorting(!(column.getIsSorted() === "desc"))
            }}
          >
            {{asc: "🔼", desc: "🔽"}[column.getIsSorted()]}
          </button>
        : null}
      <button
        className="ts-table-header-button"
        onClick={() => {
          if (column.getCanSort() && !column.getIsSorted()) column.toggleSorting(false);
          if (column.getCanFilter()) column.setFilterValue(null);
          showFeatures.setShowColumnFeatures(removeColumnById(showFeatures.showColumnFeatures, column.id))
        }}
        title={hint ? `Hide ${hint}` : ''}
      >
        {title}
      </button>
      {column.getCanFilter() ? (
        <div style={{marginTop: '2px'}}>
          <Filter column={column}/>
        </div>
      ) : null}
    </>
  );
}

export default TableHeader;
