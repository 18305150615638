import React from 'react';
import {Button, HTMLTable, Intent, Menu, MenuItem, Popover, Position} from "@blueprintjs/core";
import FamilyMemberSelect from "components/Patient/Detail/GeneSection/FamilyMemberSelect";
import {IfAuthSuperReadonly, IfNotAuthSuperReadonly} from "../../../RBAC";

// const PERSON_OPTIONS = [
//   'Maternal Grandmother',
//   'Maternal Grandfather',
//   'Paternal Grandmother',
//   'Paternal Grandfather',
//   'Daughter',
//   'Son',
//   'Aunt'
// ]

const PERSON_STATUS_OPTIONS = [
  'NA',
  'Normal',
  'Balanced',
  'Unbalanced'
]


export default (props) => {
  const {persons, onPersonRemove, onPersonUpdate} = props;

  // const personMenu = (person_key) => (
  //   <Menu>
  //     <MenuDivider title="Patient"/>
  //     <MenuItem text="Patient" onClick={() => {
  //       onPersonUpdate(person_key, {name: 'Patient'})
  //     }}/>
  //
  //     {
  //       PERSON_OPTIONS.map(item => (
  //         <MenuItem text={item} onClick={() => {
  //           onPersonUpdate(person_key, {name: `Patient's ${item}`})
  //         }}/>
  //       ))
  //     }
  //
  //     <MenuDivider title="Partner"/>
  //     <MenuItem text="Partner" onClick={() => {
  //       onPersonUpdate(person_key, {name: 'Partner'})
  //     }}/>
  //
  //     {
  //       PERSON_OPTIONS.map(item => (
  //         <MenuItem text={item} onClick={() => {
  //           onPersonUpdate(person_key, {name: `Partner's ${item}`})
  //         }}/>
  //       ))
  //     }
  //   </Menu>
  // )

  const personStatusMenu = (person_key) => (
    <Menu>
      {
        PERSON_STATUS_OPTIONS.map(item => (
          <MenuItem text={item} onClick={() => {
            onPersonUpdate(person_key, {status: item})
          }}/>
        ))
      }
    </Menu>
  )

  return (
    <div className="mutation-table">
      <HTMLTable
        style={{width: '100%'}}
        striped
        condensed
      >
        <thead>
        <tr>
          <td>
            <strong>Person</strong>
          </td>
          <td  style={{ width: 200, paddingLeft: 20}}>
            <strong>Status</strong>
          </td>
          <td/>
        </tr>
        </thead>

        <tbody>
        {
          Object.keys(persons).map((person_key) => {
            const person = persons[person_key];
            return (
              <tr>
                <td data-test-id={"qa-sr-person"}>
                  <IfNotAuthSuperReadonly>
                    {/*<Popover content={personMenu(person_key)} position={Position.RIGHT}>*/}
                    {/*  <Button minimal text={person.name}/>*/}
                    {/*</Popover>*/}
                    <FamilyMemberSelect
                      value={person.name}
                      onChange={(value) => {
                        onPersonUpdate(person_key, {name: value});
                      }}
                    />
                  </IfNotAuthSuperReadonly>
                  <IfAuthSuperReadonly>
                    {person.name}
                  </IfAuthSuperReadonly>
                </td>

                <td data-test-id={"qa-sr-person-status"}>
                  <IfNotAuthSuperReadonly>
                    <Popover content={personStatusMenu(person_key)} position={Position.RIGHT}>
                      <Button minimal fill text={person.status || 'Select'}/>
                    </Popover>
                  </IfNotAuthSuperReadonly>
                  <IfAuthSuperReadonly>
                    {person.status || 'none'}
                  </IfAuthSuperReadonly>
                </td>
                <td style={{width: 30}}>
                  <IfNotAuthSuperReadonly>
                    <Button
                      minimal
                      icon={'cross'}
                      intent={Intent.DANGER}
                      outlined={false}
                      onClick={() => onPersonRemove(person_key)}
                    />
                  </IfNotAuthSuperReadonly>
                </td>
              </tr>
            )
          })
        }
        </tbody>
      </HTMLTable>
    </div>
  )
}
