import React, {useEffect, useState} from 'react';
import {Field, FieldArray, Form, Formik} from "formik";
import {Button, Col, FormGroup, Input, Label, Modal, ModalFooter, ModalHeader, Row} from "reactstrap";
import classNames from "classnames";
import * as Yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalBody from "reactstrap/es/ModalBody";
import URI from "urijs";
import {getProviderID, getToken} from "auth/token";
import axios from "axios";
import './SampleForm.css'


const is_valid = (touched, errors, index, field) => (
  //touched['samples'] &&
  errors['samples'] &&
  //touched['samples'][index] &&
  errors['samples'][index] &&
  //touched['samples'][index][field] &&
  errors['samples'][index][field]
);

const tissue_type_options = [
  {value: 'trophectoderm', title: 'Trophectoderm'},
  {value: 'blastomere', title: 'Blastomere'},
  {value: 'polar_body', title: 'Polar Body'},
  {value: 'bgnc', title: 'BG/NC'},
  {value: 'arrested', title: 'Whole Embryo'},
  {value: 'fetal_tissue', title: 'Fetal Tissue'},
  {value: 'purified_dna', title: 'Purified DNA'},
  {value: 'wga_dna', title: 'WGA DNA'},
  {value: 'unfertilized_egg', title: 'Unfertilized Egg'},
  {value: 'arrested_opn', title: 'Arrested OPN'},
  {value: 'atr_opn', title: 'ATR OPN'},
  {value: 'wga_product', title: 'WGA Product'},
  {value: 'media', title: 'Media'},
];

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

Yup.addMethod(Yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter(v => v !== value)
        .some(v => v[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

Yup.addMethod(Yup.object, 'checkForAllOrNone', function (propertyName, message, compare=null) {
  return this.test('embryo_id', message, function(val) {
    if (!val || val[propertyName]) {
      return true;
    }
    const bool = val[propertyName].length ? true : false;
    if (
      this.parent
      .filter(obj => obj !== val)
      .every(sample => {
        const objectVal = sample[propertyName].length ? true : false ;
        return bool === objectVal
      })
    ) return true
    else {
        throw this.createError({
          path: `${this.path}.${propertyName}`,
          message
        })
      }
  })
  })

const Schema = Yup.object().shape({
  samples: Yup.array()
    .of(
      Yup.object().shape({
        embryo_number: Yup.string().nullable(),
        embryo_id: Yup.string().ensure(),
        tube_label: Yup.string()
          .min(2, 'Too Short!')
          .max(255, 'Too Long!')
          .required('Required'),
        tissue_type: Yup.string()
          .required('Required')
      }).uniqueProperty('embryo_number', 'Embryo number must be unique')
      .uniqueProperty('embryo_id', 'Embryo ID must be unique.')
      .uniqueProperty('tube_label', 'Tube Label must be unique.')
      .checkForAllOrNone('embryo_id', 'All samples must have an embryo ID.')
    )

    //.unique('Embryo number must be unique', a => a.embryo_number)
})



export default (props) => {
  const [removed, setRemoved] = useState(0);
  const [resultModalIsOpen, setResultModalIsOpen] = useState(false);
  const [sampleList, setSampleList] = useState(null);
  // const providerID = () => {
  //   return getProviderID();
  // };

  // const [{data, loading, error}] = useData('PROVIDER_SAMPLES', {}, false);
  const callAPI = (url, method, payload, providerID, patientID = null) => {


    let api_url = new URI(url);
    api_url.addSearch('provider_id', providerID);

    if (patientID) {
      api_url.addSearch('patient_id', patientID);
    }

    api_url = api_url.normalizeSearch();

    return axios({
      url: api_url.toString(),
      method: method,
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data: payload
    }).finally(() => {

    })
  };

  useEffect(() => {
    let patient_id = null;
    if (props.case.patient) {
      patient_id = props.case.patient.id;
    }
    callAPI('samples/no_pagination', 'GET', {}, getProviderID(), patient_id)
      .then((response) => {
        //console.log(response.data)
        setSampleList(response.data);
      })
      .catch(error => {
        //console.log(error);

      });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={
        {
          samples: props.initialValues
        }
      }
      validationSchema={Schema}
      onSubmit={
        (values, {setSubmitting}) => {
          values.samples.map((sample) => {
            if (typeof sample.icsi === 'undefined' && props.case.icsi === true) {
              sample.icsi = true
            }

            if (typeof sample.conventional_ivf === 'undefined' && props.case.conventional_ivf === true) {
              sample.conventional_ivf = true
            }

            if (typeof sample.embryo_number === 'string' && !sample.embryo_number) {
              sample.embryo_number = null
            }

            if (sample.tissue_type !== 'arrested' && sample.biopsy_day < 5) {
              sample.biopsy_day = null
            }

            return sample;
          })

          props.onSubmit(values);
          setSubmitting(false);
          setRemoved(0);
        }
      }
    >
      {
        ({errors, touched, setFieldValue, values, isSubmitting, initialValues}) => {
          return (
            <Form>
              <FieldArray name={'samples'}>
                {
                  (arrayHelpers) => (

                    values.samples && values.samples.length > 0 ? (
                      <div className="with-table" style={{overflowX: 'hidden'}}>
                        <div className="table-responsive">
                          <table className="table table-sm" style={{minWidth: 1400}}>
                            <thead>
                            <tr>
                              <th data-test-id={"qa-sample-embryo#-header"} style={{width: 80}}>Embryo #</th>
                              {!!props.case.report_embryo_id && <th data-test-id={"qa-sample-embryo-id-header"}>Embryo ID</th>}
                              <th data-test-id={"qa-sample-tube-label-header"}>Tube Label</th>
                              <th data-test-id={"qa-sample-rebiopsy-header"} className="th-width-90">Rebiopsy Of</th>
                              <th data-test-id={"qa-sample-tissue-type-header"} className="th-width-135">Tissue Type</th>
                              {!!props.case.icsi && <th>ICSI</th>}
                              {!!props.case.conventional_ivf && <th>CIVF</th>}
                              <th data-test-id={"qa-sample-embryo-grade-header"}>Embryo Grade</th>
                              <th data-test-id={"qa-sample-biopsy-day-header"} className="th-width-90">Biopsy Day</th>
                              <th data-test-id={"qa-sample-qc-header"} className="th-width-90">QC</th>
                              <th data-test-id={"qa-sample-rejected-header"}>Rejected</th>
                              {/*<th>Cycle</th>*/}
                              <th data-test-id={"qa-sample-biopsy-by-header"}>Biopsy By</th>
                              <th data-test-id={"qa-sample-loading-by-header"}>Loading By</th>
                              <th data-test-id={"qa-sample-witness-by-header"}>Witness By</th>
                              <th data-test-id={"qa-sample-notes-header"}>Notes</th>
                              <th />
                            </tr>
                            </thead>
                            <tbody>

                            {
                              values.samples.map((sample, index) => {
                                return (
                                  <tr key={`sample-row-${index}`}>
                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-embryo#-input"}
                                        name={`samples.${index}.embryo_number`}
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': sample[index] && sample[index].embryo_number && is_valid(touched, errors, index, 'embryo_number')
                                        })}
                                      />
                                    </td>
                                    {!!props.case.report_embryo_id && 
                                      (<td>
                                        <Field
                                          data-test-id={"qa-sample-embryo-id-input"}
                                          name={`samples.${index}.embryo_id`}
                                          className={classNames('form-control form-control-sm', {
                                            'is-invalid': is_valid(touched, errors, index, 'embryo_id')
                                          })}
                                        />
                                      <span className="error-message">{touched && errors.samples && errors.samples[index] && errors.samples[index].embryo_id}</span>
                                      </td>)
                                    }
                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-tube-label-input"}
                                        name={`samples.${index}.tube_label`}
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'tube_label')
                                        })}
                                      />
                                      <span className="error-message">{touched && errors.samples && errors.samples[index] && errors.samples[index].tube_label}</span>
                                    </td>

                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-rebiopsy-input"}
                                        name={`samples.${index}.rebiopsy_sample`}
                                        component="select"
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'rebiopsy_sample')
                                        })}
                                      >
                                        <option value={''}>Select</option>
                                        {
                                          sampleList && sampleList.map((item, k) => {
                                            if (item.tube_label) {
                                              return (
                                                <option
                                                  value={item.id}
                                                  key={`sample-re-${k}`}
                                                >
                                                  {item.tube_label}
                                                </option>
                                              )
                                            }
                                            return null
                                          })
                                        }
                                      </Field>
                                    </td>

                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-tissue-type-input"}
                                        name={`samples.${index}.tissue_type`}
                                        component="select"
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'tissue_type')
                                        })}
                                      >
                                        <option value={''}>Select</option>
                                        {
                                          tissue_type_options.map((item, j) => (
                                            (item.value === 'trophectoderm' || item.value === 'arrested') ?
                                              <option value={item.value}
                                                      key={`tissue-type-option-${j}`}>{item.title}</option>
                                              : null
                                          ))
                                        }
                                      </Field>
                                    </td>

                                    {
                                      !!props.case.icsi && (
                                        <td>
                                          <Field
                                            name={`samples.${index}.icsi`}
                                            render={({ field, form: { isSubmitting } }) => {

                                              return (
                                                <input
                                                  {...field}
                                                  disabled={isSubmitting}
                                                  type="checkbox"
                                                  checked={
                                                    typeof field.value === 'undefined' || field.value === true
                                                  }
                                                />
                                              )
                                            }}
                                          />
                                        </td>
                                      )
                                    }

                                    {
                                      !!props.case.conventional_ivf && (
                                        <td>
                                          <Field
                                            name={`samples.${index}.conventional_ivf`}
                                            render={({ field, form: { isSubmitting } }) => {

                                              return (
                                                <input
                                                  {...field}
                                                  disabled={isSubmitting}
                                                  type="checkbox"
                                                  checked={
                                                    typeof field.value === 'undefined' || field.value === true
                                                  }
                                                />
                                              )
                                            }}
                                          />
                                        </td>
                                      )
                                    }

                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-embryo-grade-input"}
                                        name={`samples.${index}.embryo_grade`}
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'embryo_grade')
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-biopsy-day-input"}
                                        name={`samples.${index}.biopsy_day`}
                                        component="select"
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'biopsy_day')
                                        })}
                                      >
                                        {sample.tissue_type === 'arrested' ? (
                                          <>
                                            <option value={''}>Select</option>
                                            <option value={'1'}>1</option>
                                            <option value={'2'}>2</option>
                                            <option value={'3'}>3</option>
                                            <option value={'4'}>4</option>
                                            <option value={'5'}>5</option>
                                            <option value={'6'}>6</option>
                                            <option value={'7'}>7</option>
                                          </>
                                        ) : (
                                          <>
                                            <option value={''}>Select</option>
                                            <option value={'5'}>5</option>
                                            <option value={'6'}>6</option>
                                            <option value={'7'}>7</option>
                                          </>
                                        )}
                                      </Field>
                                    </td>
                                <td>
                                <Field
                                        data-test-id={"qa-sample-qc-input"}
                                        name={`samples.${index}.qc_check`}
                                        component="select"
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'qc_check')
                                        })}
                                      >
                                        <option value={''}>Select</option>
                                        <option value={'pass'}>Pass</option>
                                        <option value={'fail'}>Fail</option>
                                      </Field>
                                    </td>
                                    <td>
                                      <label style={{paddingTop: 10}}>
                                        <Field data-test-id={"qa-sample-rejected-input"} type="checkbox" name={`samples.${index}.rejected`} checked={values.samples[index].rejected} />
                                      </label>
                                    </td>
                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-biopsy-input"}
                                        name={`samples.${index}.biopsy_clinician`}
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'biopsy_clinician')
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-loading-input"}
                                        name={`samples.${index}.loading_clinician`}
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'loading_clinician')
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-witness-input"}
                                        name={`samples.${index}.witness_clinician`}
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'witness_clinician')
                                        })}
                                      />
                                    </td>

                                    <td>
                                      <Field
                                        data-test-id={"qa-sample-notes-input"}
                                        name={`samples.${index}.notes`}
                                        className={classNames('form-control form-control-sm', {
                                          'is-invalid': is_valid(touched, errors, index, 'notes')
                                        })}
                                      />
                                    </td>
                                    <td>
                                      <Button
                                        data-test-id={"qa-sample-remove-sample-button"}
                                        color="link"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                          setRemoved(removed + 1)
                                        }}
                                      >
                                        <span className="text-danger"><FontAwesomeIcon icon="times"/></span>
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                            </tbody>
                          </table>
                        </div>

                        <div style={{padding: 20}}>
                          <Row>
                            <Col md="auto">
                              <Button
                                data-test-id={"qa-sample-add-sample-button"}
                                color="light"
                                size="sm"
                                onClick={() => {
                                  const new_row_data = {biopsy_clinician: '', loading_clinician: ''};

                                  if (values.samples.length > 0) {
                                    const last = values.samples[values.samples.length - 1];
                                    new_row_data.biopsy_clinician = last.biopsy_clinician;
                                    new_row_data.loading_clinician = last.loading_clinician;
                                    new_row_data.witness_clinician = last.witness_clinician;
                                  }
                                  arrayHelpers.push({...new_row_data})
                                }}
                              >
                                <FontAwesomeIcon icon="plus"/> Add Sample
                              </Button>
                            </Col>
                            <Col md="auto">
                              <Button
                                data-test-id={"qa-sameple-save-changes-button"}
                                color="info"
                                size="sm"
                                type="submit"
                              >
                                Save Changes
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    ) : (
                      <div style={{padding: 20, textAlign: 'center'}}>
                        <Button
                          color="light"
                          size="sm"
                          onClick={() => arrayHelpers.push({})}
                        >
                          <FontAwesomeIcon icon="plus"/> Add Sample
                        </Button>
                        {
                          removed > 0 && <span>{' '}
                            <Button
                              color="info"
                              size="sm"
                              type="submit"
                            >
                            Save
                          </Button>
                        </span>
                        }
                      </div>
                    )
                  )
                }
              </FieldArray>

              <Modal isOpen={resultModalIsOpen} toggle={() => setResultModalIsOpen(false)}>
                <ModalHeader toggle={() => setResultModalIsOpen(false)}>
                  Results
                </ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="karyotype">Karyotype</Label>
                    <Input type="text" name="karyotype"/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="pgtm">PGT-M Status</Label>
                    <Input type="text" name="pgtm"/>
                  </FormGroup>
                  <FormGroup>
                    <Label for="pgtp">PGT-P Panel</Label>
                    <Input type="text" name="pgtp"/>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="light" onClick={() => setResultModalIsOpen(false)}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </Form>
          )
        }
      }
    </Formik>
  )
}
