import React, {useEffect, useState} from 'react';
import {Button, FormGroup, InputGroup} from "@blueprintjs/core";
import SRPersonStatus from './SRPersonStatus';
import './style/main.css';
import {IfAuthSuperReadonly, IfNotAuthSuperReadonly} from "../../../RBAC";


const BLANK_PERSON = {
  name: '',
  status: ''
}

export const BLANK_ENTRY = {
  karyotype: '',
  persons: {
    0: {name: 'patient', status: ''},
    1: {name: 'partner', status: ''}
  }
}

export default (props) => {
  const {data, onChange} = props;

  const [values, setValues] = useState(BLANK_ENTRY);

  useEffect(() => {
    if (!!data) {
      try{
        setValues(JSON.parse(data))
      } catch (e) {
        //do nothing
      }
    }
  }, [data])

  const addPerson = () => {
    const updated_persons = {...values.persons};
    const next_key = Object.keys(updated_persons).reduce((a, b) => parseInt(a) + parseInt(b), 0) + 1;
    updated_persons[next_key] = BLANK_PERSON;
    setValues({
      ...values,
      persons: updated_persons
    });
  }

  const updatePerson = (person_key, data) => {
    const updated_persons = {...values.persons};
    updated_persons[person_key] = {...updated_persons[person_key], ...data};

    setValues({
      ...values,
      persons: updated_persons
    });
  }

  const removePerson = (person_key) => {
    const updated_persons = {...values.persons};
    if (updated_persons[person_key]) {
      delete updated_persons[person_key];
    }

    setValues({
      ...values,
      persons: updated_persons
    });
  }

  useEffect(() => {
    try {
      const json_string = JSON.stringify(values);
      onChange(json_string);
    } catch (e) {
      // do nothing
    }
  }, [values]);  // eslint-disable-line react-hooks/exhaustive-deps

  const {karyotype, persons} = values ? values : {};

  return (
    <div>
      {
        (!!values) && (
          <div>
            <div>
              <FormGroup
                label={'Karyotype'}
                labelFor={'karyotype'}
                inline
              >
                <IfNotAuthSuperReadonly>
                  <InputGroup
                    data-test-id={"qa-sr-karyotype-input"}
                    id={'karyotype'}
                    value={karyotype}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        karyotype: e.target.value
                      })
                    }}
                  />
                </IfNotAuthSuperReadonly>
                <IfAuthSuperReadonly>
                  {karyotype}
                </IfAuthSuperReadonly>
              </FormGroup>
            </div>

            <SRPersonStatus
              persons={persons}
              onPersonRemove={(person_key) => removePerson(person_key)}
              onPersonUpdate={(person_key, data) => updatePerson(person_key, data)}
            />

            <div style={{marginTop: 30}}>
              <Button
                data-test-id={"qa-sr-add-person"}
                icon={'plus'}
                minimal
                onClick={() => addPerson()}
              >
                Add Person
              </Button>
            </div>
          </div>
        )
      }
    </div>
  )
}
