// General utility constants and functions for Accessioning

export const TYPE__CLINIC_SSF = 'samples_form'; // report-type/field in Patient table/form - 'Sample Submission Forms'
export const TYPE__GP_INTERNAL_FILES = 'gp_files'; // report-type/field in Patient table/form - 'GP Internal Documents'

/**
 * Determine CP type of file from its name
 */
export const getFileTypeFromFilename = (filename) => {
  // assume PDFs are submission forms, and anything else is an internal-only image or other doc.
  if (filename.split(".").pop().toLowerCase() === 'pdf') return TYPE__CLINIC_SSF;
  return TYPE__GP_INTERNAL_FILES;
}

// YYYY-MM-DD to mm/dd/yyyy
export const displayDate = dateString => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-");
  return `${month}/${day}/${year}`;
};

/**
 * Return today's date for user's current locale
 * @returns {string} - current date in YYYY-MM-DD format
 * (can't use ISOFormat because that returns UTC date)
 */
export const todayLocalDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * dateRangeFilterFn - filter function for date columns
 * @param row - row object being evaluated for filtering (from which .value can be accessed for column)
 * @param columnId - id of column being evaluated for filtering
 * @param filterValue - array of date range to filter within [yyyy-mm-dd from-date string, yyyy-mm-dd to-date string]
 * @returns {boolean} - If true, include the row from display.  If false, exclude row from display.
 */
export const dateRangeFilterFn = (row, columnId, filterValue) => {
  const rowDateString = row.getValue(columnId);  // string: "YYYY-MM-DD" or '' or null
  if (!rowDateString) return (!filterValue[0] && !filterValue[1]);  // allow row with blank date unless a from-date is set

  // Exclude if date less than from-date
  if (filterValue[0] && rowDateString < filterValue[0]) return false;

  // Exclude if date greater than to-date
  return !(filterValue[1] && rowDateString > filterValue[1]);
}

/**
 * Convert database sample_type value to capitalized (or ALL CAPS) display value
 * @param st - lowercase value of sample_type from db
 * @returns string - display-value of given sample_type
 */
export const displaySampleType = st => {
  if (st === 'dna') return 'DNA';
  if (st === 'other') return st;
  if (st && st.length > 1) return st.charAt(0).toUpperCase() + st.slice(1); // capitalize
  return st;
}

/**
 * Convert database accessioning_type value to capitalized (or ALL CAPS) display value
 * @param at - lowercase value of accessioning_type from db
 * @returns string - display-value of given accessioning_type
 */
export const displayAccessioningType = at => {
  if (at === 'qc') return 'QC';
  if (at === 'unprocessed') return at;
  if (at && at.length > 1) return at.charAt(0).toUpperCase() + at.slice(1); // capitalize
  return at;
}

/**
 * Get user token as deposited by CPV1 login.
 * @returns {string|null} - user's auth token.  Null if fails to retrieve.
 */
export const getUserToken = () => {
  const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
  if (!auth) return null;

  const user = JSON.parse(auth.auth);
  if ('token' in user) return user.token;
  return null;
}


/**
 * Shortcut to construct parameters for axios for backend calls
 * @param {string} url - url to invoke.  Trailing slash added if not included.
 * @param {string} token - token returned from getAuthToken
 * @param {object|null} data - data, if applicable
 * @param {string} method - http method - 'GET', 'POST' or other.  'POST' is default.
 * @returns {object} - parameter object to use in axios call
 */
export const axiosParams = (url, token, data=null, method = 'POST') => {
  return {
    url: url.charAt(url.length - 1) !== '/' ? url + '/' : url,  // url must end in slash
    method: method,
    responseType: 'json',
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      "Authorization": `Token ${token}`
    },
    data: data
  }
}

