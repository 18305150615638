import React, { useState, useEffect } from 'react';
import { Checkbox, Tab, Tabs, HTMLSelect, Button, Intent } from "@blueprintjs/core";
import { Col, Row } from "reactstrap";
import Mutations from './Mutations';
import GeneForm from "./GeneForm";
import './style/main.css';
import {IfAuthSuperReadonly, IfNotAuthSuperReadonly} from "../../../RBAC";


const createBlankPerson = () => {
  return {
    name: '',
    status: ''
  }
}

const createBlankMutation = () => {
  return {
    name: 'Variant',
    chromosome: '1',
    position: 0,
    linkage: false,
    taqman: false
  }
}

const createBlankGene = () => {
  return {
    name: 'No Name',
    inheritance_pattern: 'autosomal_recessive',
    report_interpretation_status: true,
    mutations: {
      0: createBlankMutation()
    },
    persons: {
      0: createBlankPerson()
    },
    statuses: {}
  }
}

export const BLANK_ENTRY = {
  genes: {
    0: createBlankGene()
  }
}


export default (props) => {
  const { data, onChange } = props;

  const [values, setValues] = useState(BLANK_ENTRY);

  const [selectedGene, setSelectedGene] = useState('g-0');

  useEffect(() => {
    if (!!data) {
      try {
        setValues(JSON.parse(data))
      } catch (e) {
        //do nothing
      }
    }
  }, [data])

  const addGene = () => {
    const updated_genes = { ...values.genes };
    const next_key = Object.keys(updated_genes).reduce((a, b) => parseInt(a) + parseInt(b), 0) + 1;
    updated_genes[next_key] = createBlankGene();
    setValues({
      ...values,
      genes: updated_genes
    });
  }

  const updateGene = (gene_key, data) => {
    const updated_genes = { ...values.genes };
    updated_genes[gene_key] = { ...updated_genes[gene_key], ...data };

    setValues({
      ...values,
      genes: updated_genes
    });
  }

  const removeGene = (gene_key) => {
    const updated_genes = { ...values.genes };
    if (updated_genes[gene_key]) {
      delete updated_genes[gene_key];
    }

    setValues({
      ...values,
      genes: updated_genes
    });
  }

  const addMutation = (gene_key) => {
    const gene = values.genes[gene_key];

    if (gene) {
      const updated_gene = { ...gene };
      const next_key = Object.keys(updated_gene.mutations).reduce((a, b) => parseInt(a) + parseInt(b), 0) + 1;
      updated_gene.mutations[next_key] = createBlankMutation();
      const updated_genes = { ...values.genes };
      updated_genes[gene_key] = updated_gene;
      setValues({
        ...values,
        genes: updated_genes
      });
    }
  }

  const removeMutation = (gene_key, mutation_key) => {
    const gene = values.genes[gene_key];

    if (gene) {
      const updated_gene = { ...gene };

      if (updated_gene.mutations[mutation_key]) {
        delete updated_gene.mutations[mutation_key];
      }

      const updated_genes = { ...values.genes };
      updated_genes[gene_key] = updated_gene;
      setValues({
        ...values,
        genes: updated_genes
      });
    }
  }

  const updateMutation = (gene_key, mutation_key, data) => {
    const gene = values.genes[gene_key];

    if (gene) {
      const updated_gene = { ...gene };

      if (updated_gene.mutations[mutation_key]) {
        updated_gene.mutations[mutation_key] = data;
      }

      const updated_genes = { ...values.genes };
      updated_genes[gene_key] = updated_gene;
      setValues({
        ...values,
        genes: updated_genes
      });
    }
  }

  const updatePerson = (gene_key, person_key, data) => {
    const gene = values.genes[gene_key];

    if (gene) {
      const updated_gene = { ...gene };

      if (updated_gene.persons[person_key]) {
        updated_gene.persons[person_key] = { ...updated_gene.persons[person_key], ...data };
      }

      const updated_genes = { ...values.genes };
      updated_genes[gene_key] = updated_gene;
      setValues({
        ...values,
        genes: updated_genes
      });
    }
  }

  const addPerson = (gene_key) => {
    const gene = values.genes[gene_key];

    if (gene) {
      const updated_gene = { ...gene };
      const next_key = Object.keys(updated_gene.persons).reduce((a, b) => parseInt(a) + parseInt(b), 0) + 1;
      updated_gene.persons[next_key] = createBlankPerson();

      const updated_genes = { ...values.genes };
      updated_genes[gene_key] = updated_gene;

      setValues({
        ...values,
        genes: updated_genes
      });
    }
  }

  const removePerson = (gene_key, person_key) => {
    const gene = values.genes[gene_key];

    if (gene) {
      const updated_gene = { ...gene };

      if (updated_gene.persons[person_key]) {
        delete updated_gene.persons[person_key];
      }

      const updated_genes = { ...values.genes };
      updated_genes[gene_key] = updated_gene;

      setValues({
        ...values,
        genes: updated_genes
      })
    }
  }

  const updateStatus = (gene_key, key, status) => {
    const gene = values.genes[gene_key];

    if (gene) {
      const updated_gene = { ...gene };

      updated_gene.statuses[key] = status;

      const updated_genes = { ...values.genes };
      updated_genes[gene_key] = updated_gene;
      setValues({
        ...values,
        genes: updated_genes
      });
    }
  }

  useEffect(() => {
    try {
      const json_string = JSON.stringify(values);
      onChange(json_string);
    } catch (e) {
      // do nothing
    }
  }, [values]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {
        values && values.genes && values.genes !== {} ? (
          <div>
            <Tabs
              id="genes"
              onChange={(a) => {
                setSelectedGene(a);
              }}
              selectedTabId={selectedGene}
            >
              {
                Object.keys(values.genes).map((gene_key) => {
                  const gene = values.genes[gene_key];

                  const Panel = () => {
                    return (
                      <div data-test-id={"qa-pgtm-gene-name"}>
                        <Row className="justify-content-center">
                          <Col>
                            <IfNotAuthSuperReadonly>
                              <GeneForm
                                value={gene.name}
                                onChange={(v) => {
                                  updateGene(gene_key, { name: v })
                                }}
                              />
                            </IfNotAuthSuperReadonly>
                            <IfAuthSuperReadonly>
                              {gene.name}
                            </IfAuthSuperReadonly>
                          </Col>
                          <Col>
                            <IfNotAuthSuperReadonly>
                              <HTMLSelect
                                value={gene.inheritance_pattern}
                                onChange={(e) => {
                                  updateGene(gene_key, { inheritance_pattern: e.target.value })
                                }}
                              >
                                <option data-test-id={"qa-select-default"} value={''}>Select...</option>
                                <option data-test-id={"qa-autosomal-dominant"} value="autosomal_dominant" selected>Autosomal Dominant</option>
                                <option data-test-id={"qa-autosomal-recessive"} value="autosomal_recessive">Autosomal Recessive</option>
                                <option data-test-id={"qa-x-linked-dominant"} value="x_linked_dominant">X-Linked Dominant</option>
                                <option data-test-id={"qa-x-linked-recessive"} value="x_linked_recessive">X-Linked Recessive</option>
                              </HTMLSelect>
                            </IfNotAuthSuperReadonly>
                            <IfAuthSuperReadonly>
                              {gene.inheritance_pattern}
                            </IfAuthSuperReadonly>
                          </Col>
                          <Col>
                            <IfNotAuthSuperReadonly>
                              <Checkbox
                                inline
                                checked={gene.report_interpretation_status}
                                label="Report Interpretation Status"
                                data-test-id={"qa-report-interpretation-checkbox"}
                                onChange={(e) => {
                                  updateGene(gene_key, { report_interpretation_status: e.target.checked })
                                }}
                              />
                            </IfNotAuthSuperReadonly>
                            <IfAuthSuperReadonly>
                              {gene.report_interpretation_status ? '' : 'Do Not '}Report Interpretation Status
                            </IfAuthSuperReadonly>
                          </Col>
                          <Col>
                            <IfNotAuthSuperReadonly>
                              <Button
                                minimal
                                intent={Intent.DANGER}
                                data-test-id={"qa-delete-gene"}
                                icon={'cross'}
                                onClick={() => { removeGene(gene_key) }}
                              >
                                Delete Gene
                              </Button>
                            </IfNotAuthSuperReadonly>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 30 }}>
                          <Col>
                            <div data-test-id={"qa-gene-mutation-header"} className={'mutation-table-title'}>Mutations</div>
                          </Col>
                        </Row>

                        <Mutations
                          mutations={gene.mutations}
                          persons={gene.persons}
                          statuses={gene.statuses}
                          onPersonRemove={(person_key) => removePerson(gene_key, person_key)}
                          onMutationRemove={(mutation_key) => removeMutation(gene_key, mutation_key)}
                          onMutationUpdate={(mutation_key, data) => updateMutation(gene_key, mutation_key, data)}
                          onPersonUpdate={(person_key, data) => updatePerson(gene_key, person_key, data)}
                          onStatusUpdate={(key, status) => updateStatus(gene_key, key, status)}
                        />

                        <IfNotAuthSuperReadonly>
                          <div style={{ marginTop: 30 }}>
                            <Button
                              data-test-id={"qa-add-person"}
                              icon={'plus'}
                              minimal
                              onClick={() => addPerson(gene_key)}
                            >
                              Add Person
                            </Button>
                            {' '}
                            <Button
                              data-test-id={"qa-add-mutation"}
                              icon={'plus'}
                              minimal
                              onClick={() => addMutation(gene_key)}
                            >
                              Add Mutation
                            </Button>
                          </div>
                        </IfNotAuthSuperReadonly>
                      </div>
                    )
                  };

                  return (
                    <Tab
                      key={'g-' + gene_key}
                      id={'g-' + gene_key}
                      title={gene.name}
                      panel={<Panel />}
                    />
                  )
                })
              }
              <Tab
                id={'g-new'}
                disabled
              >
                <IfNotAuthSuperReadonly>
                  <Button
                    minimal
                    icon={'plus'}
                    onClick={() => addGene()}
                    className={'add-gene-btn'}
                  >
                    Add
                  </Button>
                </IfNotAuthSuperReadonly>
              </Tab>
              <Tabs.Expander />
            </Tabs>
          </div>
        ) : (
          <div>
            --
          </div>
        )
      }
    </div>
  )
}
