export const ACCESSIONING_TYPE__CLINICAL = 'clinical';
export const ACCESSIONING_TYPE__RESEARCH = 'research';
export const ACCESSIONING_TYPE__QC = 'qc';
export const ACCESSIONING_TYPE__NONE = '';

export const accessioningTypeDisplayValues = {
  'clinical': 'Clinical',
  'research': 'Research',
  'qc': 'QC'
};

export const accessioningTypeColorBg = {
  'clinical': '#ebf1f5',
  'research': '#f5f5eb',
  'qc': '#f8edf6'
}