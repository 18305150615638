/**
 * All-tab screen for Accessioning Page
 */

import React, {useState, useMemo, useEffect} from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
// import {getColumnDefinitions} from "./AllTabColumnDefs";
import '../accessioning.css';
import {
  ACCESSIONING_TYPE__CLINICAL,
  ACCESSIONING_TYPE__QC,
  ACCESSIONING_TYPE__RESEARCH
} from "../common/accessioningTypes";

// tanstack headless UI components -- see tanstack.com for docs, etc.
import Pagination from "./Pagination";

// Fake data - if needed for testing
// import {data} from "../../../accession_data";

import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import {Button, Dialog, InputGroup} from "@blueprintjs/core";
import table from "reactstrap/es/Table";
import axios from "axios";

import {getUserToken} from "../common/utils";
import NewEmbryonicForm from "./NewEmbryonicForm";
import {linkExistingAccessioningBatchToCase} from "../common/LinkAccessioningBatchToCase";
import {getColumnDefinitions} from "./AllTabColumnDefs";


/**
 * Compute the sum of sample-quantity column.  Only sum from filtered rows currently showing.
 * @param table - tanstack table instance
 * @returns number - sum of sample-quantity column values of filtered rows currently showing
 */
const sumSampleQuantity = (table) => {
  const filteredRowModel = table.getFilteredRowModel();
  if (!('rows' in filteredRowModel)) return 0;
  return filteredRowModel.rows.reduce((total, row) => total + row.getValue('sample_quantity'), 0);
}

/**
 * All-Tab screen for Accessioning - shows all Accessioning Batches in all states
 * @param {function} setTotalSampleQuantity - state hook fn to set totalSampleQuantity
 */
export default function AllTab({setTotalSampleQuantity}) {
  const pagination = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: 10,
    }),
    [],
  );

  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');  // Value from big search input box at top

  // Sorting state for each column of tanstack table.  Initially sort by batch id descending
  const [sorting, setSorting] = useState([{id: 'id', desc: true}]);
  const [columnVisibility, setColumnVisibility] = useState({
    id: true,
    case_id: true,
    potential_case_id: false,   // shown only when user has case-match filter checked
    clinic: true,
    sample_type: true,
    accessioning_type: true,
    received_at: true,
    preamp_date: true,
    axiom_date: true,
    accessioning_status: true,
    sample_quantity: true
  });
  const [columnFilters, setColumnFilters] = useState([]);  // each column's filtering in tanstack table

  // which columns are showing their features (sorting and/or filtering) in header
  const [showColumnFeatures, setShowColumnFeatures] = useState([]);

  const [isCaseMatchChecked, setIsCaseMatchChecked] = useState(false);

  // Embryonic Modal - see accessioningTypes.js
  const [addBatchDropdownOpen, setAddBatchDropdownOpen] = useState(false);  // +Add button
  const [showNewEmbryonicModal, setShowNewEmbryonicModal] = useState('');

  // Retrieve all batches from back-end db
  const loadAllBatches = () => {
    const token = getUserToken();
    if (!token) return;

    axios({
      url: 'accessioning/list_for_all_tab/',  // url must end in slash
      method: 'GET',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "Authorization": `Token ${token}`
      }
    }).then((response) => {
      setData(response.data);
    }).catch((error) => {
      console.error('GET accessioning/list failed', error);
    });
  };


  // on initialization, load list of all batches into data
  useEffect(() => loadAllBatches(), []);

  const handleCaseLink = (info, patientId) => {
    linkExistingAccessioningBatchToCase(info.row.getValue('id'), patientId).then(response => {
      alert('link successful');
      setData(oldData => oldData.filter((_, idx) => idx !== info.row.index)) // delete row
    }).catch(error => {alert('an error occurred: ' + error)})
  }

  const columnDefinitions = React.useMemo(() => getColumnDefinitions(handleCaseLink), []);

  // definition for tanstack table that holds list of all batches
  const AllBatchTSTable = useReactTable({
    data,
    columns: columnDefinitions,
    initialState: {
      pagination
    },
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {},
    state: {
      sorting,
      columnFilters,
      columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });


  // Set the total of all sample quantities -- shared
  // https://profy.dev/article/react-useeffect-with-object-dependency
  useEffect(() => {
    setTotalSampleQuantity(sumSampleQuantity(AllBatchTSTable));
  }, [...Object.values(AllBatchTSTable)]);  // eslint-disable-line

  // Reset all filtering, sorting, et al
  const resetAll = () => {
    AllBatchTSTable.resetColumnFilters(true);
    AllBatchTSTable.getColumn('id').toggleSorting('desc');
    setShowColumnFeatures([]);  // remove all feature components from column headers
  };

  const composeMatchupString = (matchupMapRow) => {
    // GPCL_ID # case id # clinic (provider) id
    return matchupMapRow['first_tube_label'].split('-')[0] + '#' + matchupMapRow['case_id'] + '#' + matchupMapRow['provider_id']
  }

  /**
   * Called from case-matchup checkbox on-click
   * @param toggle  true/false - is checkbox checked?
   */
  const setCaseMatchup = toggle => {
    if (!toggle) {
      //
      // Restore normal view (remove "case-matchup filter")
      //
      setColumnVisibility(old => ({...old,
        case_id: true,
        potential_case_id: false
      }));
      loadAllBatches();
      setIsCaseMatchChecked(false);
    } else {
      //
      // Show Case-Matchup "filter"
      //
      const token = getUserToken();
      if (!token) return;

      axios ({
        url: 'accessioning/list_potential_case_matchups/',  // url must end in slash
        method: 'GET',
        responseType: 'json',
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
          "Authorization": `Token ${token}`
        }
      }).then((response) => {
        const matchupMap = {};
        response.data.forEach(row => matchupMap[row["batch_id"]] = composeMatchupString(row));
        const newData = data.filter(row => row["id"] in matchupMap).map(row => {
          return {...row, potential_case_id: matchupMap[row["id"]]};
        })
        if (newData.length > 0) {
          setColumnVisibility(old => ({...old,
            case_id: false,
            potential_case_id: true,
          }));
          setData(newData);
          setIsCaseMatchChecked(true);
        } else {
          alert("No new matching cases found");
        }
      }).catch((error) => {
        console.error('GET accessioning/case-matchup-list failed', error);
      });
    }
  };

  //
  // Render
  //
  return (
    <>
      {/*New Embryonic Modal dialog*/}
      <Dialog icon="plus" title={`New Embryonic Accessioning`}
              className='accessioning-modal' isOpen={showNewEmbryonicModal !== ''}
              onClose={() => { setShowNewEmbryonicModal(''); window.location.reload(); }}
      >
        <NewEmbryonicForm accessioningType={showNewEmbryonicModal} onClose={() => { setShowNewEmbryonicModal(''); window.location.reload(); }} />
      </Dialog>

      {/* All-Batch listing screen */}
      {!data ? (
        <div>Loading ...</div>
        ) :
      <Card>
        {/* Header.  Contains Search, reset, +Add */}
        <CardHeader>
          <Container fluid>
            <Row>
              <Col>
                {/* Search box */}
                <div style={{display: "inline-block", width: "300px"}}>
                  <InputGroup
                    disabled={false}
                    large={false}
                    placeholder="Search patient name or dob ..."
                    rightElement={<Button type="submit" icon={'search'} style={{borderRadius: 99}} loading={false}
                                          minimal/>}
                    small={false}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="search"
                  />
                </div>
                {/* Reset button */}
                <div className="sortFilterResetButton" title="restore default sorting and remove all filtering"
                     onClick={resetAll}>
                  reset sort/filter
                </div>
                {/* Case-Matchup checkbox */}
                <div style={{display: "inline-block", marginLeft: "2.4rem"}}>
                  <label style={{padding: "6px", backgroundColor: "#fef", borderRadius: "10px"}}>
                    <input type="checkbox" style={{marginRight: ".4rem"}}
                           checked={isCaseMatchChecked}
                           onChange={(e) => setCaseMatchup(e.target.checked)}
                    />
                    match potential cases
                  </label>
                </div>
              </Col>
              <Col xs lg="2" className="text-right">
                {/* +Add a batch */}
                <ButtonDropdown isOpen={addBatchDropdownOpen} toggle={() => setAddBatchDropdownOpen(!addBatchDropdownOpen)}>
                  <DropdownToggle color="primary">
                    +Add
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setShowNewEmbryonicModal(ACCESSIONING_TYPE__CLINICAL)}>Embryonic</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => window.alert('New Other/DNA has not yet been implemented')}>Other DNA</DropdownItem>
                    <DropdownItem onClick={() => setShowNewEmbryonicModal(ACCESSIONING_TYPE__RESEARCH)}>Research</DropdownItem>
                    <DropdownItem onClick={() => setShowNewEmbryonicModal(ACCESSIONING_TYPE__QC)}>QC</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            </Row>
          </Container>
        </CardHeader>

        {/*
          Table of Accessioning Batches
          */}
        <table style={{borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray"}}>
          <thead style={{backgroundColor: '#ebe9f2'}}>
            {AllBatchTSTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className='accessioningTR' style={{height: "50px"}}>
                {headerGroup.headers.map((header) => {
                  const headerContext = header.getContext();
                  // Inject this into header's context so TableHeader can access it
                  headerContext.showFeatures = {
                    showColumnFeatures: showColumnFeatures,
                    setShowColumnFeatures: setShowColumnFeatures,
                  };
                  return (
                    <th key={header.id} className='accessioningTH' style={header.column.id === 'clinic' ? {textAlign: 'left'} : {}}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          headerContext,
                        )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody style={{borderBottom: "1px solid lightgray"}}>
            {AllBatchTSTable.getRowModel().rows.length === 0 ? (
              <tr>
                <td colSpan={100} style={{padding: "2rem", textAlign: "center"}}><i>No matching entries found</i></td>
              </tr>
            ) : (
              AllBatchTSTable.getRowModel().rows.map((row) => (
              <tr key={row.id} className='accessioningTR'>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={{height: "50px"}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            )))}
          </tbody>
        </table>

        {/*Row of paging controls beneath accessioning table*/}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          height: '50px',
          backgroundColor: '#ebe9f2'
        }}>
          <Pagination
              table={AllBatchTSTable}
              pageRange={3}
              pageOptions={AllBatchTSTable.getPageOptions()}
          >
            <Pagination.Goto table={AllBatchTSTable} options={[10, 20, 30, 40, 50]}/>
          </Pagination>
        </div>

      </Card>}
    </>
  );
};
