import React, {useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Button, Col, FormFeedback, FormGroup, Label, Row} from "reactstrap";
import classNames from "classnames";
import * as Yup from 'yup';
import validator from 'validator';
import {ETHNICITIES} from "constants/case";
import DOB from "utils/Formik/DOB";
import AddressInput from 'components/Address/Modal';
import DiseaseHistoryModal from "components/Patient/DiseaseHistory/Modal";
import axios from "axios";
import {getToken} from "../../../auth/token";
import {connect} from "react-redux";


const AddressSchema = Yup.object().shape({
  address: Yup.object().nullable().notRequired()
});

const Schema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  email: Yup.string().nullable()
    .email('Must be a valid email'),
  dob: Yup.date().nullable()
    .test(
      'dob',
      'Must be a valid date',
      value => {
        if (validator.isDate(value)) {
          const diff = (new Date() - value) / (1000 * 3600 * 24 * 365);
          return diff > 0 && diff < 100;
        }
        return true;
      }
    ),
  phone: Yup.string().nullable()
    .test(
      'phone',
      'Phone number is not valid',
      value => value ? validator.isMobilePhone(value) : true
    )
});

export default connect(
    state => ({
        providerId: state.patient.item.provider,
    }),
    null
) (
    props => {
      const { case_data } = props;
      const isBlindedM2 = case_data && case_data.m2 && case_data.blinded;

      const [error, setError] = useState(null);

      const submitPerson = values => {
        const url = `persons/${values.id ? (values.id + '/') : ''}`;
        const queryparams = `?provider_id=${props.providerId}${props.patient ? '&patient=1' : ''}`;
        const method = values.id ? 'PUT' : 'POST';

        return axios({
            baseURL: process.env.REACT_APP_API_HOST,
            url: url + queryparams,
            method,
            responseType: 'json',
            validateStatus: () => true,
            headers: {
                "Authorization": `Token ${getToken()}`
            },
            data: values
        });
      };

      const handleSubmit = (values, setSubmitting) => {
          if (values.dob === '') {
              values.dob = null
          }

          if (props.salivaSample) {
              props.onSuccess(values)
              setSubmitting(true)
          } else {
              if (props.patient) {
                  values['patient_case'] = case_data.id
              } else {
                  values['partner_case'] = case_data.id
              }

              submitPerson(values).then(res => {
                  if (res.status === 409) {
                      setError('The same patient already exists in the portal')
                      setSubmitting(false)
                  } else {
                      props.onSuccess()
                      setSubmitting(true)
                  }
              }).catch(res => {
                  setError(res.message)
                  setSubmitting(false)
              });
          }
      }

      return (
        <div>
          <Formik
            initialValues={{
              first_name: isBlindedM2 ? 'Blinded' : '',
              last_name: isBlindedM2 ? 'Blinded' : '',
              dob: '',
              sex: '',
              ethnicity: '',
              fdh: '',
              email: '',
              phone: '',
              related_to: '',
              related_as: '',
              address_same: false,
              address: null,
              ...props.initialValues
            }}

            onSubmit={
              (values, { setSubmitting }) => {
                  handleSubmit(values, setSubmitting)
              }
            }

            validationSchema={isBlindedM2 ? null : (props.addressRequired ? Schema.concat(AddressSchema) : Schema)}
          >
            {
              ({ errors, touched, values, isSubmitting, setFieldValue, handleSubmit, handleChange }) => {
                return (
                  <Form autoComplete="off">
                    <FormGroup row>
                      <Label data-test-id={"qa-first-name-label"} for="first_name" sm={4}>
                        First Name
                        {!isBlindedM2 && (<span className="text-danger">*</span>)}
                      </Label>
                      <Col sm={8}>
                        <Field
                          data-test-id={"qa-first-name-input"}
                          type="text"
                          name="first_name"
                          disabled={isBlindedM2}
                          className={classNames('form-control', {
                            'is-invalid': touched.first_name && !!errors.first_name
                          })}
                          onChange={e => {
                              if (error) {
                                  setError(null)
                              }
                              handleChange(e);
                          }}
                        />
                        <FormFeedback>
                          <ErrorMessage name="first_name" component="span" />
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label data-test-id={"qa-last-name-label"} for="last_name" sm={4}>
                        Last Name
                        {!isBlindedM2 && (<span className="text-danger">*</span>)}
                      </Label>
                      <Col sm={8}>
                        <Field
                          data-test-id={"qa-first-name-input"}
                          type="text"
                          name="last_name"
                          disabled={isBlindedM2}
                          className={classNames('form-control', {
                            'is-invalid': touched.last_name && !!errors.last_name
                          })}
                          onChange={e => {
                              if (error) {
                                  setError(null)
                              }
                              handleChange(e);
                          }}
                        />
                        <FormFeedback>
                          <ErrorMessage name="last_name" component="span" />
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label data-test-id={"qa-dob-label"} for="dob" sm={4}>DOB</Label>
                      <Col sm={8}>
                        <Field
                          data-test-id={"qa-dob-input"}
                          type="text"
                          component={DOB}
                          name="dob"
                          placeholder="mm/dd/yyyy"
                          onChange={() => {
                              if (error) {
                                  setError(null)
                              }
                          }}
                        />
                      </Col>
                    </FormGroup>
                    {
                      props.salivaSample ? (
                        <div>
                          <FormGroup row>
                            <Label for="related_to" sm={4}>Related To</Label>
                            <Col sm={8}>
                              <Field
                                name="related_to"
                                className={classNames('form-control', {
                                  'is-invalid': touched.related_to && errors.related_to
                                })}
                                component="select"
                              >
                                <option value={''}>Select</option>
                                {
                                  props.patient && (
                                    <option
                                      value={`${props.patient.first_name} ${props.patient.last_name} (Patient)`}
                                    >
                                      {props.patient.first_name} {props.patient.last_name} (Patient)
                                    </option>
                                  )
                                }
                                {
                                  props.partner && (
                                    <option
                                      value={`${props.partner.first_name} ${props.partner.last_name} (Partner)`}
                                    >
                                      {props.partner.first_name} {props.partner.last_name} (Partner)
                                    </option>
                                  )
                                }
                              </Field>
                              <ErrorMessage name="related_to" component="span" className="error form-error" />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label for="related_as" sm={4}>Related As</Label>
                            <Col sm={8}>
                              <Field
                                name="related_as"
                                className={classNames('form-control', {
                                  'is-invalid': touched.related_as && errors.related_as
                                })}
                                component="select"
                              >
                                <option value={''}>Select</option>
                                <option value={'daughter'}>Daughter</option>
                                <option value={'son'}>Son</option>
                                <option value={'mother'}>Mother</option>
                                <option value={'father'}>Father</option>
                                <option value={'aunt'}>Aunt</option>
                                <option value={'uncle'}>Uncle</option>
                                <option value={'sister'}>Sister</option>
                                <option value={'brother'}>Brother</option>
                                <option value={'cousin_female'}>Cousin (female)</option>
                                <option value={'cousin_male'}>Cousin (male)</option>
                              </Field>
                              <ErrorMessage name="related_as" component="span" className="error form-error" />
                            </Col>
                          </FormGroup>
                        </div>
                      ) : (
                        <div>
                          <FormGroup row>
                            <Label data-test-id={"qa-sex-label"} for="sex" sm={4}>Sex</Label>
                            <Col sm={8}>
                              <Field
                                data-test-id={"qa-sex-input"}
                                component="select"
                                name="sex"
                                className={classNames('form-control', {
                                  'is-invalid': touched.sex && !!errors.sex
                                })}
                              >
                                <option value={''}> </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Field>
                              <FormFeedback>
                                <ErrorMessage name="sex" component="span" />
                              </FormFeedback>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label data-test-id={"qa-ethnicity-label"} for="sex" sm={4}>Ethnicity</Label>
                            <Col sm={8}>
                              <Field
                                data-test-id={"qa-ethnicity-input"}
                                component="select"
                                name="ethnicity"
                                className={classNames('form-control', {
                                  'is-invalid': touched.sex && !!errors.sex
                                })}
                              >
                                <option value={''}> </option>
                                {
                                  Object.keys(ETHNICITIES).map((key) =>
                                    <option value={key} key={`ethnicity-${key}`}>{ETHNICITIES[key]}</option>
                                  )
                                }
                              </Field>
                              <FormFeedback>
                                <ErrorMessage name="ethnicity" component="span" />
                              </FormFeedback>
                            </Col>
                          </FormGroup>
                        </div>
                      )
                    }

                    {
                      !props.salivaSample && (
                        <div>
                          <hr />

                          <h5>Contact</h5>

                          <FormGroup row>
                            <Label data-test-id={"qa-pp-phone-label"} for="phone" sm={4}>Phone</Label>
                            <Col sm={8}>
                              <Field
                                data-test-id={"qa-pp-phone-input"}
                                type="text"
                                name="phone"
                                className={classNames('form-control', {
                                  'is-invalid': touched.phone && !!errors.phone
                                })}
                              />
                              <FormFeedback>
                                <ErrorMessage name="phone" component="span" />
                              </FormFeedback>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label data-test-id={"qa-pp-email-label"} for="email" sm={4}>Email</Label>
                            <Col sm={8}>
                              <Field
                                data-test-id={"qa-pp-phone-input"}
                                type="text"
                                name="email"
                                className={classNames('form-control', {
                                  'is-invalid': touched.email && !!errors.email
                                })}
                              />
                              <FormFeedback>
                                <ErrorMessage name="email" component="span" />
                              </FormFeedback>
                            </Col>
                          </FormGroup>

                          <hr />

                          <h5>Address {props.patient && (!isBlindedM2 && (<span className="text-danger"></span>))}</h5>

                          {
                            props.partner ? (
                              <FormGroup row>
                                <Label for="address_same" sm={4}>Same as Patient's</Label>
                                <Col sm={8} style={{ paddingTop: 8 }}>
                                  <Field
                                    name="address_same"
                                    render={({ field, form: { isSubmitting } }) => (
                                      <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value} />
                                    )}
                                  />
                                </Col>
                              </FormGroup>
                            ) : (
                              ''
                            )
                          }

                          {
                            ((props.partner && !values.address_same) || props.patient) &&
                            <AddressInput
                              onSubmit={(data) => setFieldValue('address', data)}
                              onDelete={() => setFieldValue('address', null)}
                              initialValues={values.address}
                            />
                          }

                          <div>
                            {
                              (touched.address && errors.address) && <span className="text-danger">{errors.address}</span>
                            }
                          </div>

                          <hr />

                          <h5>Family Disease History</h5>

                          <DiseaseHistoryModal
                            initialData={values.fdh}
                            onChange={(data) => {
                              setFieldValue('fdh', JSON.stringify(data));
                            }}
                          />
                        </div>
                      )
                    }

                    {/*<FormGroup row>*/}
                    {/*<Label for="address.address_1" sm={4}>Address 1</Label>*/}
                    {/*<Col sm={8}>*/}
                    {/*<Field*/}
                    {/*type="text"*/}
                    {/*name="address.address_1"*/}
                    {/*className={classNames('form-control', {*/}
                    {/*'is-invalid': touched.address && !!errors.address*/}
                    {/*})}*/}
                    {/*/>*/}
                    {/*<FormFeedback>*/}
                    {/*<ErrorMessage name="address.address_1" component="span"/>*/}
                    {/*</FormFeedback>*/}
                    {/*</Col>*/}
                    {/*</FormGroup>*/}

                      {error && <div className="d-flex justify-content-center text-danger mt-2"> {error} </div>}
                    <Row style={{ marginTop: 20 }} className="justify-content-start">
                      <Col md="auto">
                        <Button
                                data-test-id={"qa-pp-submit-button"}
                                type="button"
                                color="success"
                                disabled={
                                    isSubmitting || !!Object.keys(errors).length ||
                                    (!props.initialValues  && !Object.keys(touched).length)
                                }
                                onClick={() => { handleSubmit() }}>
                            Submit
                        </Button>
                      </Col>
                      <Col md="auto">
                        <Button data-test-id={"qa-pp-cancel-button"} color="light" onClick={() => props.onCancel()}>Cancel</Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            }
          </Formik>
        </div>
      )
    }
  )
