import React, {useState, useRef, useEffect} from "react";
import {Button, Col, Row} from "reactstrap";
import ClinicDropdown from "./ClinicDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik, Form, Field, ErrorMessage, FieldArray} from "formik";
import {Dialog} from "@blueprintjs/core";
import axios from "axios";
import {NumericField} from "../../../utils/Formik2/NumericField";
import {
  validateRequiredDOB,
  validateRequiredRecentPastDate,
  validateRequiredString
} from "../../../utils/Formik2/validators";
import {linkAccessioningBatchToCase} from "../common/LinkAccessioningBatchToCase";
import {
  axiosParams,
  TYPE__CLINIC_SSF,
  TYPE__GP_INTERNAL_FILES,
  getFileTypeFromFilename,
  todayLocalDate
} from "../common/utils";
import {
  ACCESSIONING_TYPE__CLINICAL,
  accessioningTypeColorBg,
  accessioningTypeDisplayValues
} from "../common/accessioningTypes";

//
// A note on variable case [Seth]
// camelCase is standard for variable naming (e.g. filesToUpload), but
// when a variable is used to holds data to/from db tables, it will be named in snake_case instead (e.g. embryo_number)
// to minimize errors/confusion.

/**
 * Display New Embryonic form for clinical, research or QC accessioning types.  Called within <dialog>
 *   props
 *      {string} accessioningType - 'clinical', 'research', 'qc' - see accessioningTypes.js
 *      {function} onClose - function that signals this dialog should close
 * @returns {JSX.Element}
 */
export default function NewEmbryonicForm(props) {
  const [accessioningType, setAccessioningType] = useState(props.accessioningType);

  // Clinic (provider) selected by user
  const [clinic, setClinic] = useState(null);   // {id: provider id, company_name: clinic name}

  const [showErrorAlertModal, setShowErrorAlertModal] = useState(false);

  const [modalAlertMessage, setModalAlertMessage] = useState("");  // validation or error modal

  const [modalDisplayMatchedCaseId, setModalDisplayMatchedCaseId] = useState("");
  const [modalMatchingCaseUrl, setModalMatchingCaseUrl] = useState("");
  const [modalDupGPCLIDFound, setModalDupGPCLIDFound] = useState("");

  // Array of files that have been uploaded.  Each array element is {file_name, url, ext, datetime}:
  //      file_name - bare file name e.g. 'important-report.pdf'
  //      url - file's address/nametag within S3.  i.e. what's used in browser to access file directly.
  //      ext - file's extension/type.  E.g. pdf, jpg, doc, xlsx ...
  //      datetime - datetime file was uploaded in iso format.  E.g. '2024-10-14 09:50 PM'
  const [filesUploaded, setFilesUploaded] = useState([]);

  const [saveDraftInProgress, setSaveDraftInProgress] = useState(false);
  const [draftWasSaved, setDraftWasSaved] = useState(false);
  const [draftData, setDraftData] = useState(null); // saved/retrieved draft data fields

  const [formikInitialValues, setFormikInitialValues] = useState({
    received_at: todayLocalDate(),  // default sample-received to today's date
    patient_first_name: "",
    patient_last_name: "",
    patient_dob: null,
    embryo_samples: []
  });

  // Form doesn't display until setupComplete.
  // Setup not complete until draft evaluated/loaded/discarded, if applicable
  // or, if editing, accessioning batch values loaded.
  const [setupComplete, setSetupComplete] = useState(false);
  const [fileUploadsInProgress, setFileUploadsInProgress] = useState(false); // avoid double-processing

  const {onClose} = props;

  const hiddenInputFileButtonRef = useRef(null); // ref to hidden <input type='file' ...
  const draftDialogRef = useRef(null);  // ref to draft dialog
  const resolveDraftDialogRef = useRef(null);  // ref to resolve draft dialog promise
  const linkDialogRef = useRef(null);  // ref to link dialog
  const resolveLinkDialogRef = useRef(null);  // ref to resolve link dialog promise
  const clinicDialogRef = useRef(null); // ref to clinic selector dialog

  // Acceptable extensions for file uploads
  const acceptedFileExtensions = ["pdf","png","jpg","jpeg","xls","xlsx","txt","rtf","doc","docx"];
  const acceptedFileTypeRegex = new RegExp(acceptedFileExtensions.join("|"), "i");
  const acceptedFileTypesString = acceptedFileExtensions.map((ext) => `.${ext}`).join(",") +
                                            ",application/vnd.openxmlformats-officedocument.wordprocessingml.document"


  // First, try to retrieve draft for previous Accessioning Batch session
  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      axios(axiosParams('accessioning-drafts', token, null, 'GET')).then((response) => {
        if (response.data) {
          // User has a prior draft.  Show it to them and give them choices.
          const snapshotData = JSON.parse(response.data.snapshot);
          const formValues = snapshotData.formValues;
          setDraftData({
            accessioningType: snapshotData.accessioningType,
            datetime: response.data.created_at,
            clinicId: snapshotData.clinic_id,
            clinicName: response.data.clinic_name ? response.data.clinic_name : '(no clinic chosen)',
            formValues: formValues,
            filesUploaded: snapshotData.filesUploaded,
            patientFirstName: formValues.patient_first_name ? formValues.patient_first_name : '(no first name)',
            patientLastName: formValues.patient_last_name ? formValues.patient_last_name : '(no last name)',
            patientDOB: formValues.patient_dob ? formValues.patient_dob : '',
            sampleCount: formValues.embryo_samples ? formValues.embryo_samples.length : 0,
          });  // triggers next useEffect - processing continues there ...
        } else
          setSetupComplete(true);
      }).catch((error) => {
        alertAndFail(`Unable to retrieve your last draft session - ${error}`);
        setSetupComplete(true);  // Set setupComplete to true even if there's an error
      })
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps


  // If a draft exists, show dialog and act on user's choice
  useEffect(() => {
    if (draftData) {
      showModalDraftDialog().then(draftResponse => {
        if (draftResponse === 'RESUME') {
          loadDraft();
        } else if (draftResponse === 'DISCARD') {
          discardDraft();
          setSetupComplete(true);
        } else {  // draftResponse is 'QUIT'
          onClose();
        }
      })
    }
  }, [draftData]); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   * loadDraft.  User has chosen to resume their previous work draft.  Load data and trigger display.
   */
  const loadDraft = () => {
    setAccessioningType('accessioningType' in draftData ? draftData.accessioningType : ACCESSIONING_TYPE__CLINICAL);
    setClinic({id: draftData.clinicId, company_name: draftData.clinicName});
    setFilesUploaded(draftData.filesUploaded);
    setFormikInitialValues(draftData.formValues);
    setSetupComplete(true);
  };

  /**
   * discardDraft.  User has chosen to discard their previous work.  Remove any uploaded files, and draft record.
   */
  const discardDraft = () => {
    const token = getAuthToken();
    if (token) {
      draftData.filesUploaded.forEach((fileUploadedItem) => {
        if (getFileTypeFromFilename(fileUploadedItem.file_name) === TYPE__CLINIC_SSF) {
          axios(axiosParams('file/0', token, {
                type: TYPE__CLINIC_SSF,
                file: JSON.stringify(fileUploadedItem),  // item, as returned when uploaded
                accessioning: 'standalone'
              },
              'DELETE'
            )
          )
        } else {
          axios(axiosParams('file/0', token, {
                type: TYPE__GP_INTERNAL_FILES,
                file: JSON.stringify(fileUploadedItem),  // item, as returned when uploaded
                accessioning: 'standalone'
              },
              'DELETE'
            )
          )
        }
      });

      deleteDraft();
    }
  }

  // iso YYYY-MM-DD HH:MM:SS to user's local date time
  const isoUTCtoLocalDateTime = isoString => {
    const date = new Date(isoString);

    const options = {
      weekday: 'long',   // Day of the week
      year: 'numeric',   // Full year
      month: 'long',     // Full month name
      day: 'numeric',    // Day of the month
      hour: 'numeric',   // Hours
      minute: 'numeric', // Minutes
      hour12: true       // Use 12-hour AM/PM format
    };

    return date.toLocaleDateString(undefined, options);
  };

  /**
   * Based on count, e.g., display "no things", "1 thing", "3 things"
   * @param count
   * @param thing
   * @returns {string}
   */
  const displayCount = (count, thing) => {
    if (count === undefined || count === null || count === 0) return `no ${thing}s`;
    if (count === 1) return `1 ${thing}`;
    return `${count} ${thing}s`;
  }


  /**
   * get user's auth token needed for API calls.
   * @param {boolean} silent - display alert if unable to get token?  True, remain silent.  False, display alert.
   * @returns {string|false} - returns token string.  If unable to get token, returns false.
   */
  const getAuthToken = (silent=false) => {
    const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
    const user = auth ? JSON.parse(auth.auth) : null;
    const token = user ? user.token : null;
    if (!auth || !user || !token) {
      if (!silent) return alertAndFail(`Authorization lost.  Try logging back in.`);
      return false;
    }
    return token;
  }


  /**
   * Save current form data (clinic, values, files, etc.) to accessioning_batch_draft table for current user
   * so user can resume input the next time they access this screen.
   * Called whenever a file is uploaded/deleted or when user clicks Save Draft.
   * @param {object} values - Formik values
   * @param {object[]|null} newFilesUploaded - new value for filesUploaded.  Needed because saveDraft called
   *                                           within setState functions when files added/deleted.
   */
  const saveDraft = (values, newFilesUploaded = null) => {
    if (!saveDraftInProgress) {
      setSaveDraftInProgress(true);
      const token = getAuthToken();
      if (!token) {
        setSaveDraftInProgress(false);
        return;
      }

      const snapshotData = {
        accessioningType: accessioningType,
        clinic_id: clinic ? clinic.id : null,
        formValues: values,
        filesUploaded: newFilesUploaded !== null ? [...newFilesUploaded] : [...filesUploaded]
      };
      axios(axiosParams('accessioning-drafts', token, {snapshot: JSON.stringify(snapshotData)})).then(response => {
        setSaveDraftInProgress(false);
        setDraftWasSaved(true);
        setTimeout(() => setDraftWasSaved(false), 3000);
      }).catch((error) => {
        setSaveDraftInProgress(false);
        alertAndFail(`Unable to save your draft session - ${error}`);
      })
    }
  };

  const deleteDraft = () => {
    const token = getAuthToken();
    if (!token) return;

    axios(axiosParams('accessioning-drafts', token, null, 'DELETE')).then((response) => {
      // Do nothing
    }).catch((error) => {
      alertAndFail(`? Unable to delete your draft session ? - ${error} - please notify IT department`);
    })
  };

  const viewFile = url => {
    const token = getAuthToken();
    if (!token) return;

    axios.post('/api/get-uploaded-files-presigned-url', {
      file_name: url
    }, {
      headers: {
        "Authorization": `Token ${token}`
      },
    }).then((res) => {
      window.open(res.data.presigned_url, '_blank')
    })
  };

  /**
   * Add file(s) that user chose (with upload button or Drag&Drop) to the upload-files list
   * @param filesToUpload - array of files to add to upload-files list
   * @param values - Formik values object
   */
  const uploadTheseFiles = (filesToUpload, values) => {
    if (fileUploadsInProgress) return;

    setFileUploadsInProgress(true);
    filesToUpload.forEach((fileToUpload) => {
      if (filesUploaded.some((fileAlreadyUploaded) => fileAlreadyUploaded.name === fileToUpload.name)) {
        // Do nothing if file already uploaded
        // setFileUploadsInProgress(false);
      } else if (!acceptedFileTypeRegex.test(fileToUpload.name.split(".").pop())) {
        setFileUploadsInProgress(false);
        alertAndFail(`file(s) skipped - only ${acceptedFileExtensions.join(", ")} files allowed`);
      } else { // Upload the file
        // read file data
        const reader = new FileReader();

        reader.onloadend = () => {
          // file read successfully, upload it
          const token = getAuthToken();
          if (token) {
            axios(axiosParams('file/0', token, {
              file_name: fileToUpload.name,
              type: getFileTypeFromFilename(fileToUpload.name),
              file: reader.result,  // file contents
              accessioning: 'standalone'
            })).then((response) => {
              setFilesUploaded(curFilesUploaded => {
                const newFilesUploaded = [...curFilesUploaded, response.data.new_item];
                saveDraft(values, newFilesUploaded);
                return newFilesUploaded;
              });
              setFileUploadsInProgress(false);
            }).catch((error) => {
              setFileUploadsInProgress(false);
              alertAndFail(`Unable to upload ${fileToUpload.name} - ${error}`);
            })
          }
        };

        reader.readAsDataURL(fileToUpload);
      }
    })
    setFileUploadsInProgress(false);
  }

  /**
   * Remove an uploaded file from S3 and upload-files array (after user hits delete (X) button)
   * @param {number} index - index of element to remove
   * @param values - Formik values object
   */
  const deleteUploadedFile = (index, values) => {
    const token = getAuthToken();
    if (!token) return;

    const fileUploadedItem = filesUploaded[index];
    axios(axiosParams('file/0', token, {
      type: getFileTypeFromFilename(fileUploadedItem.file_name),
      file: JSON.stringify(fileUploadedItem),  // item, as returned when uploaded
      accessioning: 'standalone'
    }, 'DELETE')).then((response) => {
      setFilesUploaded(curFilesUploaded => {
        const newFilesUploaded = [...curFilesUploaded];
        newFilesUploaded.splice(index, 1);
        saveDraft(values, newFilesUploaded);
        return newFilesUploaded;
      });
    }).catch((error) => {
      alertAndFail(`Unable to delete ${fileUploadedItem.file_name} - ${error}`);
    })
  };


  /**
   * Display error alert Dialog with given message.
   * @param {string} message - message to display
   * @returns {boolean} - always false, as a convenience for additionalValidation
   */
  const alertAndFail = message => {
    setModalAlertMessage(message);
    setShowErrorAlertModal(true);
    return false;
  }

  /**
   * Additional validation steps to take before saving accessioning batch.
   * If any step fails validation, display alert dialog
   * @param {object} values
   * @returns {boolean} - true if valid for submission; false if not
   */
  const additionalValidation = (values) => {
    // clinic dropdown not within Formik, so have to verify separately
    if (accessioningType === ACCESSIONING_TYPE__CLINICAL)
      if (!clinic || !clinic.id) return alertAndFail("Choosing a Clinic is Required");

    // fail if user didn't add any samples
    const sampleArray = values.embryo_samples;
    if (sampleArray.length === 0) return alertAndFail("? Batch contains no samples ?");

    // check for duplicate embryo numbers or tube labels
    const embryo_numberSet = new Set();
    const tube_labelSet = new Set();
    for (let index = 0; index < sampleArray.length; index++) {
      const embryo_number = sampleArray[index].embryo_number;
      if (embryo_numberSet.has(embryo_number))
        return alertAndFail(`Sample ${index+1} duplicates Embryo # ${embryo_number}`);
      embryo_numberSet.add(embryo_number);

      const tube_label = sampleArray[index].tube_label;
      if (tube_labelSet.has(tube_label))
        return alertAndFail(`Sample ${index+1} duplicates Tube Label ${tube_label}`);
      tube_labelSet.add(tube_label);
    }

    // passed all additional validations
    return true;
  }

  /**
   * Present a RESUME/DISCARD/QUIT dialog to user when they have a prior session (saved in draft).
   * @returns {Promise} - resolves to user's choice 'RESUME', 'DISCARD' or 'QUIT'
   */
  const showModalDraftDialog = () => {
    draftDialogRef.current.showModal();

    return new Promise((resolve) => {
      resolveDraftDialogRef.current = resolve;     // Store promise's resolve function
    });
  };

  /**
   * Present a YES/NO/CANCEL dialog to user when there is a potential match-up of batch to a case
   * @returns {Promise} - resolves to user's choice 'YES', 'NO' or 'CANCEL'
   */
  const showModalLinkDialog = () => {
    linkDialogRef.current.showModal();

    return new Promise((resolve) => {
      resolveLinkDialogRef.current = resolve;     // Store promise's resolve function
    });
  };

  const showClinicSelectorDialog = () => {
    clinicDialogRef.current.showModal();
  }

  /**
   * During submit, a matching case was found for this accessioning batch and choice was presented to user.
   * This function called if user then opted to link this batch to the matching case.
   * It sets case id, samples and files, then saves accessioning batch
   *
   * @param {number} patient_id - case (patient table) row id
   * @param {string} token
   * @param {Object} accessioningBatch - new AccessioningBatch object (Formik field values + ...)
   * @param setSubmitting - formik; is submission in progress
   */
  const linkAccessioningBatchToCaseAndSave = (patient_id, token, accessioningBatch, setSubmitting) => {
    linkAccessioningBatchToCase(accessioningBatch, patient_id, token).then(newAccessioningBatch => {
      saveAccessioningBatch(token, newAccessioningBatch, setSubmitting);
    }).catch(error => {
      alertAndFail('Submit failed.  Please notify IT department.');
      console.error('submit failed', error);
    });
  }

  /**
   * During submit, a matched case was found.
   * Ask user if they want to link this Accessioning batch to it, just save the batch without linking the case,
   * or just forget the whole thing
   *
   * @param {Object} matchFound - response returned by get_accessioning_match
   * @param {Object} accessioningBatch - new AccessioningBatch object (Formik field values + ...)
   * @param token
   * @param setSubmitting
   */
  const askLinkSave = (matchFound, accessioningBatch, token, setSubmitting) => {
    if (matchFound.data.status === 'duplicate_gpcl') {
      const gpclIdMatch = accessioningBatch.embryo_samples[0].tube_label.match(/^(\d+)-(\d+)$/);
      if (gpclIdMatch)
        setModalDupGPCLIDFound(gpclIdMatch[1]);
      else
        setModalDupGPCLIDFound('');
    } else
        setModalDupGPCLIDFound('');
    setModalDisplayMatchedCaseId(matchFound.data.case_id ? matchFound.data.case_id : `#${matchFound.data.id}`);
    setModalMatchingCaseUrl(`../patients/${matchFound.data.id}?provider=${accessioningBatch.provider_id}`);
    showModalLinkDialog().then(linkUserResponse => {
      if (linkUserResponse === 'YES') {
        linkAccessioningBatchToCaseAndSave(matchFound.data.id, token, accessioningBatch, setSubmitting)
      }
      else if (linkUserResponse === 'NO') {
        // just save the accessioning.  Leave the matching case alone.
        saveAccessioningBatch(token, accessioningBatch, setSubmitting);
      }
      else {  // linkUserResponse is 'CANCEL'
        setSubmitting(false);
      }
    })
  };

  /**
   * Start the submit process when user clicks the Submit button within Formik form.
   * @param {Object} values - Formik field values
   * @param {function} setSubmitting - set false to tell Formik that submit process is over (whether good or bad result)
   */
  const doSubmit = (values, setSubmitting) => {
    // check for duplicates and other non-Formik validation.  Abort if failed.
    if (!additionalValidation(values)) {
      setSubmitting(false);
      return;
    }

    // Fill out rest of values object to make it into an Accessioning Batch row object
    values.provider_id = clinic ? clinic.id : null;
    values.accessioning_type = accessioningType;
    values.sample_type = 'embryonic';
    values.patient_id = null;  // no case match
    values.live_flag = 1;  // 1 = live, 0 = draft, -1 = deleted
    values.embryo_samples.forEach(embryo_sample => {
      embryo_sample.accessioning_status = 'accessioned';
      embryo_sample.QC = (embryo_sample.QC === 'pass');  // convert to boolean
    });
    values.files = [...filesUploaded];

    const token = getAuthToken();
    if (!token) {
      setSubmitting(false);
      return;
    }

    // Check for case match-up if all samples provided ...     (i.e. no sample is 'not provided')
    if (!values.embryo_samples.some(embryo_sample => embryo_sample.tissue_type === 'notprovided')) {
      // ... and clinic and full patient name specified
      if (clinic && values.patient_first_name && values.patient_last_name && values.patient_dob) {
        // Does this new batch match-up to an existing Case?
        axios(axiosParams('patients/get_accessioning_match', token, values)).then(matchFoundResponse => {
          if (matchFoundResponse.data && matchFoundResponse.data.status !== 'none') {
            if (matchFoundResponse.data.status === 'error') {
              setSubmitting(false);
              alertAndFail('Match-up failed.  Please notify IT department.');
            } else {  // 'matched' or 'duplicate_gpcl'
              askLinkSave(matchFoundResponse, values, token, setSubmitting);
            }
          } else { // batch matches no extant cases - continue with submit
            saveAccessioningBatch(token, values, setSubmitting);
          }
        }).catch(error => {
          setSubmitting(false);
          alertAndFail('Match-up failed.  Please notify IT department.');
          console.error('match-up failed', error);
        })
      }
    }
  }

  /**
   * Save new accessioning batch object to db (incl. data, samples, files)
   * @param token
   * @param {Object} accessioningBatch - new AccessioningBatch object (Formik field values + ...)
   * @param setSubmitting
   */
  const saveAccessioningBatch = (token, accessioningBatch, setSubmitting) => {
    axios(axiosParams('accessioning', token, accessioningBatch)).then(response => {
      deleteDraft();
      onClose();
    })
    .catch(error => {
      alertAndFail('Submit failed.  Please notify IT department.');
      console.error('submit failed', error);
    })
    .finally(() => {
      setSubmitting(false); // tell Formik we're done with submission
    });
  }

  //
  // Render
  //
  return (
    <>
      {/*
          Dialog Displayed when additional validation finds an issue, or other error
        */}
      <Dialog title="Alert" isOpen={showErrorAlertModal} className='accessioning-error-alert-modal'
        onClose={() => setShowErrorAlertModal(false)}
      >
        <div className='m-3'>{modalAlertMessage}</div>
      </Dialog>

      {/*
          Dialog Displayed initially when user has a previous draft -- Resume (load draft), Discard (delete draft) or Quit
        */}
      <dialog ref={draftDialogRef} className='accessioning-success-alert-modal' style={{width: '640px'}}>
        {draftData ? (
          <>
          <div className="accessioning-choice-modal text-right" onClick={onClose}>x</div>
          <div className="mt-3 mb-2 text-center">You have a draft saved <b>{isoUTCtoLocalDateTime(draftData.datetime)}</b> for:</div>
          {'accessioningType' in draftData && <div className="text-center">{`Type: ${accessioningTypeDisplayValues[draftData.accessioningType]}`}</div>}
          <div className="text-center">{draftData.clinicName}</div>
          <div className="text-center">{draftData.patientFirstName} {draftData.patientLastName}
            {draftData.patientDOB ? ` b. ${draftData.patientDOB}` : ''}</div>
          <div className="text-center">
            {displayCount(draftData.sampleCount, 'sample')} -{' '}
            {`${displayCount(draftData.filesUploaded.length, 'file')} uploaded`}
          </div>
          <div className='m-3 d-flex justify-content-center'>
            <table style={{ border: 0, borderCollapse: 'separate', borderSpacing: '10px 25px' }}>
              <tbody>
              <tr className='accessioning-choice-modal' onClick={() => {
                      draftDialogRef.current.close();
                      if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('RESUME');
                    }}
              >
                <td style={{ marginBottom: '15px' }}>
                  <Button color="success" onClick={() => {
                      draftDialogRef.current.close();
                      if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('RESUME');
                    }}
                  >
                    RESUME
                  </Button>
                </td>
                <td className='text-left' style={{ marginBottom: '15px' }}>
                  Load this draft and Resume working on it
                </td>
              </tr>
              <tr className='accessioning-choice-modal' onClick={() => {
                      draftDialogRef.current.close();
                      if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('DISCARD');
                    }}
              >
                <td style={{ marginBottom: '15px' }}>
                  <Button color="info" onClick={() => {
                      draftDialogRef.current.close();
                      if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('DISCARD');
                    }}
                  >
                    DISCARD
                  </Button>
                </td>
                <td className='text-left' style={{ marginBottom: '15px' }}>
                  Discard this draft.<br/>Values, samples and all uploaded files removed.
                </td>
              </tr>
              <tr className='accessioning-choice-modal' onClick={() => {
                    draftDialogRef.current.close();
                    if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('QUIT');
                  }}
              >
                <td>
                  <Button color="warning" className='mr-2' onClick={() => {
                    draftDialogRef.current.close();
                    if (resolveDraftDialogRef.current) resolveDraftDialogRef.current('QUIT');
                  }}
                  >
                    QUIT
                  </Button>
                </td>
                <td className='text-left'>
                  Do nothing (exit)
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          </>) : ''}
      </dialog>

      {/*
          Dialog displayed after submission when new batch matches up to existing case
        */}
      <dialog ref={linkDialogRef} className='accessioning-success-alert-modal' style={{width:'640px'}}>
        <div className='m-3'>
          Clinic and Patient match existing Case <a href={modalMatchingCaseUrl} target="_blank" title='Open case in new window'
                                              rel="noopener noreferrer">{modalDisplayMatchedCaseId}</a><br />
          Link Accessioning to this case when saving ?<br /><br />
          {modalDupGPCLIDFound ? `>> Cannot Link - GPCL ID ${modalDupGPCLIDFound} already in use by another case <<`: ''}
        </div>
        <Row className='mt-4 mr-0'>
          <Col md={4}>
            &nbsp;
          </Col>
          <Col md={4}>
            <Button autoFocus disabled={modalDupGPCLIDFound > ""} className='mr-2' onClick={() => {
              linkDialogRef.current.close();
              if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('YES');
             }}
            >
              YES
            </Button>
            <Button color="info" onClick={() => {
              linkDialogRef.current.close();
              if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('NO');
            }}>
              NO
            </Button>
          </Col>
          <Col md={4} className='ml-auto'>
            <Button color="warning" className='mr-2' onClick={() => {
              linkDialogRef.current.close();
              if (resolveLinkDialogRef.current) resolveLinkDialogRef.current('CANCEL');
            }}>
              CANCEL
            </Button>
          </Col>
        </Row>
      </dialog>

      <dialog ref={clinicDialogRef} style={{width:'640px'}}>
        <ClinicDropdown setClinic={setClinic} clinicDialogRef={clinicDialogRef} />
      </dialog>

      {/*
          New Embryonic Form screen
        */}
      {setupComplete ? (
        <Formik initialValues={formikInitialValues}
          onSubmit={(values, {setSubmitting}) => doSubmit(values, setSubmitting)}
        >
          {
            ({values, isSubmitting}) => {
              return (
                <Form autoComplete="off" style={{backgroundColor: accessioningTypeColorBg[accessioningType]}}>
                  <Row style={{fontSize: '150%'}}>
                    <Col md={{size: 7, offset: 1}} className='p-1'>
                      {accessioningTypeDisplayValues[accessioningType]}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    {/*
                        Clinic input dropdown
                    */}
                    <Col md={{size: 7, offset: 1}} className='p-1'>
                      <div>Clinic</div>
                      <div style={{border: "1px solid black", padding: "3px", backgroundColor: "white", cursor: "pointer"}} onClick={showClinicSelectorDialog}>
                        {clinic ? clinic.company_name : <span style={{fontStyle: "italic"}}>Click to select</span>}
                      </div>
                    </Col>
                        {/*
                        Sample Received Date
                      */}
                        <Col md={3} className='p-1'>
                        <div>Sample Received</div>
                      <div><Field name="received_at" type="date" validate={validateRequiredRecentPastDate} /></div>
                      <ErrorMessage name="received_at">
                        {msg => <div className='text-danger'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    {/*
                        Patient name, DOB
                    */}
                    <Col md={{size: 7, offset: 1}} className='p-1'>
                      <Row className='w-100'>
                        <Col md={6}>
                          <div>Patient</div>
                          <div>
                            <Field name="patient_first_name" type="text" className='w-100'
                                      validate={accessioningType === ACCESSIONING_TYPE__CLINICAL ? validateRequiredString : null}/>
                          </div>
                          <small>first name</small>
                          <ErrorMessage name="patient_first_name">
                            {msg => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </Col>
                        <Col md={6}>
                          <div>&nbsp;</div>
                          <div>
                            <Field name="patient_last_name" type="text" className='w-100'
                                   validate={accessioningType === ACCESSIONING_TYPE__CLINICAL ? validateRequiredString : null}/>
                          </div>
                          <small>last name</small>
                          <ErrorMessage name="patient_last_name">
                            {msg => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3} className='p-1'>
                      <div>DOB</div>
                      <div>
                        <Field name="patient_dob" type="date" validate={accessioningType === ACCESSIONING_TYPE__CLINICAL ? validateRequiredDOB : null}/>
                      </div>
                      <ErrorMessage name="patient_dob">
                        {msg => <div className='text-danger'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  {/*
                      File Upload Zone
                  */}
                  <Row className="mt-2 w-100">
                    <Col md={{size: 12, offset: 1}} className='p-1'>
                      <div><h5>SSF or Accessioning Photo Files</h5></div>
                      {/* Show files Uploaded. */}
                      {filesUploaded.length === 0 ? <div><i>No files uploaded</i></div> : ''}
                      {filesUploaded.map((file, index) => (
                        <div style={{fontSize: '80%', fontWeight: 'bold'}}>
                          <span onClick={() => {viewFile(file.url)}} style={{cursor: "pointer"}}>{file.file_name}</span>
                          {/* Delete X Button */}
                          <Button color="link" size="sm" onClick={event => {
                            event.stopPropagation();
                            deleteUploadedFile(index, values);
                          }}>
                            <span className="text-danger ml-2" title="Remove this uploaded file">
                              <FontAwesomeIcon icon="times"/>
                            </span>
                          </Button>
                        </div>
                      ))}
                      {/*Dotted zone to drag/upload files*/}
                      <div className='mt-2 w-75 rounded-lg justify-content-center align-items-center text-center'
                           style={{border: '2px dotted gray', backgroundColor: '#e2e9ec', height: '12vh'}}
                           onDragOver={(e) => e.preventDefault()}
                           onDrop={(event) => {
                             event.preventDefault();
                             uploadTheseFiles(Array.from(event.dataTransfer.files), values);
                           }}
                      >
                        <div className='mt-2'>Drag &amp; Drop SSF, photo or other relevant files here</div>
                        <div>or</div>
                        <Button color="primary" className='mt-1' onClick={() => hiddenInputFileButtonRef.current.click()}>Browse Files from your Computer</Button>
                        <input
                          type="file"
                          id="hiddenInputFileButton"
                          name="hiddenInputFileButton"
                          multiple
                          accept={acceptedFileTypesString}
                          ref={hiddenInputFileButtonRef}
                          className="hidden"
                          onChange={(event) => {
                            uploadTheseFiles(Array.from(event.target.files), values);
                          }}
                          onClick={(event) => {
                            event.target.value = null;  // to allow selecting again
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <hr className="w-100"/>
                  </Row>
                  {/*
                      Embryo Samples Table
                  */}
                  <FieldArray name="embryo_samples">
                    { ({ push, remove }) => (
                      <>
                      <Row className="mt-2">
                        <Col md={{size: 1, offset: 1}} className='p-1'>
                          <h5>Samples</h5>
                        </Col>
                        <Col>
                          <Button color="primary" size='sm' className='ml-2'
                                  onClick={() => push({
                                    embryo_number: "",
                                    tube_label: "",
                                    tissue_type: "",
                                    issue: "",
                                    QC: "pass",
                                    on_hold: false,
                                    note: ""
                                  })}
                          >
                            [+]
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{size: 10, offset: 1}} className='p-1'>
                          <table className='w-100 mb-3 table-bordered'>
                            <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th style={{width: '15%'}}>Tube Label</th>
                              <th style={{width: '10%'}}>Embryo #</th>
                              <th style={{width: '15%'}}>Sample Type</th>
                              <th style={{width: '20%'}}>Issue</th>
                              <th style={{width: '10%'}}>QC</th>
                              <th style={{width: '5%'}}>Hold</th>
                              <th style={{width: '35%'}}>Note</th>
                              <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {values.embryo_samples && values.embryo_samples.length > 0 && values.embryo_samples.map((sample, index) => (
                              <tr key={index}>
                                <td className='text-center'>{index + 1}</td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].tube_label`}
                                    className='border-0 w-100 text-center'
                                    validate={validateRequiredString}
                                  />
                                  <ErrorMessage name={`embryo_samples[${index}].tube_label`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].embryo_number`}
                                    className='border-0 w-100 text-center'
                                    component={NumericField}
                                    validate={validateRequiredString}
                                  />
                                  <ErrorMessage name={`embryo_samples[${index}].embryo_number`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].tissue_type`}
                                    component="select"
                                    className='border-0 w-100'
                                    validate={validateRequiredString}
                                  >
                                    <option value=""></option>
                                    <option value="trophectoderm">Trophectoderm</option>
                                    <option value="arrested">Whole Embryo</option>
                                    <option value="notprovided">Not Provided</option>
                                    <option value="nc">NC</option>
                                  </Field>
                                  <ErrorMessage name={`embryo_samples[${index}].tissue_type`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].issue`}
                                    component="select"
                                    className='border-0 w-100'
                                  >
                                    <option value=""></option>
                                    <option value="stickerMissing">Sticker Missing</option>
                                    <option value="tubeMismatch">Tube-Label Mismatch</option>
                                    <option value="other">other</option>
                                  </Field>
                                  <ErrorMessage name={`embryo_samples[${index}].issue`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td>
                                  <Field
                                    name={`embryo_samples[${index}].QC`}
                                    component="select"
                                    className='border-0 w-100'
                                  >
                                    <option value="pass">Pass</option>
                                    <option value="fail">FAIL</option>
                                  </Field>
                                  <ErrorMessage name={`embryo_samples[${index}].QC`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td className='text-center'>
                                  <Field
                                    name={`embryo_samples[${index}].on_hold`}
                                    type="checkbox"
                                    className='border-0'
                                  />
                                </td>
                                <td>
                                  <Field name={`embryo_samples[${index}].note`} className='border-0 w-100' type='text'/>
                                  <ErrorMessage name={`embryo_samples[${index}].note`}>
                                    {msg => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </td>
                                <td onClick={() => remove(index)}>
                                  <span className="text-danger ml-2" title="Delete this sample">
                                    <FontAwesomeIcon icon="times"/>
                                  </span>
                                </td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      </>
                    )}
                  </FieldArray>
                  {/* Buttons at bottom of form */}
                  <Row>
                    <Col md={4}>
                      &nbsp;
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <Button color="info" onClick={() => saveDraft(values)} disabled={saveDraftInProgress}>
                        Save Draft</Button>
                      {draftWasSaved && <span className="ml-2 text-success strong">Draft Saved</span>}
                    </Col>
                    <Col md={4} className='ml-auto'>
                      <Button color="success" className='mr-3' type="submit" disabled={isSubmitting}>Submit</Button>
                      <Button color="warning" className='mr-3' onClick={onClose}>Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              );
            }
          }
        </Formik>
      ) : (
        <div className='mt-3 mb-2 ml-1'>Waiting for Draft choice...</div>
      )}
    </>
  );
};
