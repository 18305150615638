import React, { useState, useEffect } from 'react';
import useData from "api/useData";
import { Menu, MenuItem, Spinner } from "@blueprintjs/core";
import classNames from 'classnames';

/**
 * Does what user entered match first part (or entire) first or last name?
 * @param {string} userEntry - string that user inputted
 * @param {string} firstName
 * @param {string} lastName
 * @returns {boolean}
 */
const nameIsMatch = (userEntry, firstName, lastName) => {
  if (userEntry.length <= lastName.length) {
    if (lastName.substr(0, userEntry.length).toLowerCase() === userEntry) return true;
  }

  if (userEntry.length <= firstName.length) {
    if (firstName.substr(0, userEntry.length).toLowerCase() === userEntry) return true;
  }

  return false;
}

/**
 * <SelectPerson> component
 * @param props
 * @returns {JSX.Element}
 */
export default (props) => {
  const {
    onSelect,  // function to invoke when user chooses person ---  onSelect(person)
    isPatient, // true - choosing patient; false - choosing partner
    caseId     // case id value to add to person row
  } = props;
  const [{ data, error, loading }] = useData('PERSON', { nameonly: 1 }); // load from person table
  const [matchList, setMatchList] = useState([]);
  const [nameMatch, setNameMatch] = useState('');
  const [isLoadingAfterSelect, setIsLoadingAfterSelect] = useState(false); // New state variable
  const MATCH_LIMIT = 15;  // maximum number of matches to show

  // Read data into matchList whenever user enters something (or data changes)
  // nameMatch = what user inputted
  // data = row data from person table
  useEffect(() => {
    const m = [];  // list of names to match against
    const d = [];  // copy of person data
    if (data) {
      for (let n = 0; n in data; n++) d[n] = data[n];
      if (!nameMatch) {  // no name search specified; just show first N items in list
        for (const person of d) {
          if (person.first_name === 'Blinded' || person.last_name === 'Blinded') continue;
          m.push(person);
          if (m.length >= MATCH_LIMIT) {
            setMatchList(m);
            return;
          }
        }

        setMatchList(m);
        return;
      }

      for (const person of d) {
        if (person.first_name === 'Blinded' || person.last_name === 'Blinded') continue;

        if (nameMatch && nameIsMatch(nameMatch, person.first_name, person.last_name)) {
          m.push(person);
          if (m.length >= MATCH_LIMIT) {
            setMatchList(m);
            return;
          }
        }
      }
    }
    setMatchList(m);
  }, [data, nameMatch]);

  /**
   * User has selected a person from list.  Invoke caller's onSelect function.
   * @param {Object} values - person row chosen (clicked) by user from menu
   */
  const selectPerson = (values) => {
    setIsLoadingAfterSelect(true); // Set the loading state to true
    if (isPatient) {
      values.patient_case = caseId
    } else {
      values.partner_case = caseId
    }

    onSelect(values);
  };

  // trigger match-list useEffect whenever user enters text in input box (and lowercase it)
  const nameChangeHandler = (e) => {
    setNameMatch(e.target.value.toLowerCase());
  }

  //
  // Render
  //
  return (
    <div>
      {
        (loading && !data) &&
        <div style={{ padding: '120px 0 120px 0', textAlign: 'center' }}>
          <Spinner size={50} />
        </div>
      }

      {
        (error && <div>{error.message}</div>)
      }

      {
        data && (
          <div>
            <div style={{ marginBottom: 10, marginTop: 20 }}>
              Search Name:&nbsp;<input type="text" value={nameMatch} onChange={nameChangeHandler} />
            </div>
            {matchList.length === 0 ? <div>No matches found</div> : null}
            {matchList.length > 0 ? (
              <Menu>
                {matchList.map((person, index) => (
                  <MenuItem key={index}
                    text={`${person.first_name} ${person.last_name}`}
                    onClick={() => selectPerson(person)}
                    className={classNames({ 'bp3-skeleton': loading })}
                    icon={'user'}
                    style={{ marginBottom: 4 }}
                  />
                ))}
              </Menu>
            ) : null
            }
          </div>
        )
      }

      {
        isLoadingAfterSelect && // Conditionally render the "Loading..." text
        <div style={{ padding: '20px', textAlign: 'center' }}>
          Loading...<Spinner size={50} />
        </div>
      }
    </div>
  )
}
